import { SvgInfoCircular } from "@itwin/itwinui-icons-react";
import {
  Divider,
  Button,
  Text,
  ExpandableBlock,
  IconButton,
  Surface,
  Modal,
} from "@itwin/itwinui-react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  IAdminProducers,
  IProducerAdminEntity,
} from "../../interfaces/IAdminProducers";
import { IConsumerAdminEntity } from "../../interfaces/IPagedAdminConsumers";
import { EventDefinitionExpandableBlock } from "./eventDefinitionExpandableBlock/eventDefinitionExpandableBlock";
import { SubscribedConsumersExpandableBlock } from "./subscribedConsumersExpandableBlock/subscribedConsumersExpandableBlock";
import { GovernanceButton } from "./governanceButton/governanceButton";

export const AdminProducerTile = (props: {
  producer: IProducerAdminEntity;
  consumers: IConsumerAdminEntity[];
  updateProducers: () => Promise<IAdminProducers>;
}) => {
  const { producer, consumers, updateProducers } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const rawJsonWithoutEventDefs = useMemo(() => {
    let withoutEventDef = structuredClone(producer);
    delete withoutEventDef.eventDefinitions;
    return JSON.stringify(withoutEventDef, null, 4);
  }, [producer]);

  const subscribedConsumers = useMemo(() => {
    return consumers.filter((consumer) =>
      consumer.subscriptions.some((sub) => sub.producerId === producer.id)
    );
  }, [consumers, producer.id]);

  const eventDefinitionBlocks = useMemo(() => {
    return producer.eventDefinitions.map((eventDef) => {
      return (
        <EventDefinitionExpandableBlock
          eventDef={eventDef}
          consumers={subscribedConsumers}
          key={eventDef.id}
          updateProducers={updateProducers}
        />
      );
    });
  }, [producer.eventDefinitions, subscribedConsumers]);

  return (
    <Surface
      elevation={1}
      style={{
        height: "600px",
        width: "420px",
        margin: "14px",
      }}
    >
      <Surface.Header>
        <Text variant="subheading" as="h2">
          {producer.name}{" "}
          {producer.state.toString() === "Pending" ? (
            <b style={{ color: "red" }}>(PENDING)</b>
          ) : (
            ""
          )}
        </Text>
        <IconButton
          onClick={() => setModalIsOpen(true)}
          style={{ border: "none", marginLeft: "auto" }}
        >
          <SvgInfoCircular />
        </IconButton>
        <Modal
          isOpen={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          closeOnEsc
          isDismissible
          title={`${producer.name} - Raw Json`}
        >
          <div>
            <pre>{rawJsonWithoutEventDefs}</pre>
          </div>
        </Modal>
      </Surface.Header>
      <Surface.Body isPadded={true}>
        <Text variant="body" as="p" style={{ marginBottom: "4px" }}>
          {producer.description}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Id:</b> {producer.id}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>ClientIds:</b> {producer.clientIds}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Contact:</b>{" "}
          {!!producer.contactEmail ? (
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:${producer.contactEmail}`;
                e.preventDefault();
              }}
            >
              {producer.contactEmail}
            </Link>
          ) : (
            "None"
          )}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>GPRId:</b> {producer.serviceGPRId}
        </Text>
        <Text variant="body" as="div" style={{ marginBottom: "8px" }}>
          <b>State:</b> {producer.state}{" "}
          {producer.state.toString() === "Pending" ? (
            <GovernanceButton
              governanceType="Producer"
              id={producer.id}
              updateProducers={updateProducers}
            />
          ) : (
            ""
          )}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Status:</b> {producer.status}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>IsReady:</b> {String(producer.isReady)}
        </Text>
        <Text variant="body" as="p" style={{ marginBottom: "8px" }}>
          <b>Created:</b> {new Date(producer.createdTimestamp).toDateString()}{" "}
          {producer.modifiedTimestamp !== producer.createdTimestamp &&
          producer.modifiedTimestamp
            ? `(Modified: ${new Date(
                producer.modifiedTimestamp
              ).toDateString()})`
            : ""}
        </Text>
        <Text variant="body" as="div" style={{ marginBottom: "8px" }}>
          <SubscribedConsumersExpandableBlock consumers={subscribedConsumers} />
        </Text>
        <b>Event Definitions: </b>
        {eventDefinitionBlocks}
      </Surface.Body>
    </Surface>
  );
};
